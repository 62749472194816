
* {
	padding: 0px;
	margin: 0;
	box-sizing: border-box;
}

body {
	background: #E8E8E8;
	font-family: 'Open Sans', sans-serif;
}

legend {
  border: 1px solid rgb(153, 153, 153);
  border-radius: 15px;
  width:inherit; /* Or auto */
  padding:0 20px;
  background-color: dodgerblue; 
  color: white;
  font-family: 'Merriweather', sans-serif;
  }
.optionBaja {
  color: dodgerblue;
}

.optionMedia {
  color: orange;
}

.optionAlta {
  color: #ff0000;
}


.contenedor {
	min-height: 100vh;
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.formulario {
	min-width: 600px;
	margin-top: 50px;
	background: #fff;
	border-radius: 5px;
	padding: 20px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.formulario > div {
	margin-bottom: 20px;
}

.formulario label {
	display: block;
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 5px;
	color: #1f1f1f;
  padding-left: 20px;
}

.formulario input,
.formulario textarea {
	font-family: 'Open Sans', sans-serif;
	width: 100%;
	border-radius: 5px;
	border: 2px solid #e2e2e2;
	font-size: 18px;
	padding: 10px;
  padding-left: 30px;
	margin-bottom: 5px;
	color: #1f1f1f;
}

.formulario input:focus {
	outline: none;
	border: 2px solid #0085FF;
}

.formulario .error {
	color: #e92b2d;
	font-weight: 600;
	font-size: 12px;
}

.formulario button[type="submit"] {
	display: block;
	background: #0085FF;
	font-weight: 600;
	font-family: 'Open Sans', sans-serif;
	border: none;
	cursor: pointer;
	width: 100%;
	padding: 10px;
	border-radius: 5px;
	color: #fff;
	font-size: 16px;
	transition: .3s ease all;
}
.formulario select[type="text"] {
  height: 34px;
  padding: 5px 0 5px 5px;
  background: transparent;
  border: none;
}

.formulario img {
  width: auto;

}

.formulario fieldset {
	border: 2px solid #edeef0;
	border-radius: 10px;
  padding-left: 30px;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 30px;

}

.formulario button[type="submit"]:hover {
	background: #0051ff;
}

.formulario .exito {
	text-align: center;
	font-size: 14px;
	padding-top: 20px;
	color: #02a802;
}

.formulario input[type="radio"] {
	width: auto;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html, body {
  background: #f1f1f1;
  font-family: 'Merriweather', sans-serif;
  padding: 1em;
}

h1 {
  text-align: center;
  color: #a8a8a8;
  text-shadow: 1px 1px 0 white;
}

form {
  max-width: 600px;
  text-align: center;
  margin: 20px auto;
}
form input, form textarea {
  border: 0;
  outline: 0;
  padding: 1em;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  display: block;
  width: 100%;
  margin-top: 1em;
  font-family: 'Merriweather', sans-serif;
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  resize: none;
}
form input:focus, form textarea:focus {
  -moz-box-shadow: 0 0px 2px #e74c3c !important;
  -webkit-box-shadow: 0 0px 2px #e74c3c !important;
  box-shadow: 0 0px 2px #e74c3c !important;
}
form #input-submit {
  color: white;
  background: #0051ff;
  cursor: pointer;
}
form #input-submit:hover {
  -moz-box-shadow: 0 1px 1px 1px rgba(170, 170, 170, 0.6);
  -webkit-box-shadow: 0 1px 1px 1px rgba(170, 170, 170, 0.6);
  box-shadow: 0 1px 1px 1px rgba(170, 170, 170, 0.6);
}
form textarea {
  height: 126px;
}
div #toolstip {
	width: 320px;
	padding: 10px;
	border: 5px solid gray;
	margin: 0;
  }
.half {
  float: left;
  width: 48%;
  margin-bottom: 1em;
}

.right {
  width: 50%;
}

.left {
  margin-right: 2%;
}

@media (max-width: 480px) {
  .half {
    width: 100%;
    float: none;
    margin-bottom: 0;
  }
}
/* Clearfix */
.cf:before,
.cf:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.cf:after {
  clear: both;
}
